import React, { useEffect, CSSProperties, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { images, spacing, color } from '../Layouts/theme';
import {
  MOBILE_HEADER_TH,
  MOBILE_HEADER_EN,
  HEADER_EN,
  HEADER_TH,
  BODY_2_EN,
  BODY_2_TH,
  CONTENT_CARD,
  CONTENT_CENTER,
} from '../Layouts/styled/main';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import i18next from 'i18next';

const ColBox = styled(Col)`
  padding-right: 0px !important;
  padding-left: 0px !important;
  @media (max-width: 576px) {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
`;

const Contact = () => {
  const { t } = useTranslation();



  const HEDAER_STYLE: CSSProperties = {
    ...(i18next?.language === 'th' ? HEADER_TH : HEADER_EN),
    textAlign: 'center',
  };

  const BODY_STYLE: CSSProperties = {
    ...(i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN),
    textAlign: 'center',
  };

  return (
    <main className={'main'}>
      <section className="is-not-hd-size">
        <Container fluid>
          <Row className="justify-content-center">
            <ColBox xs="12" data-aos="fade-up">
              <div data-aos="fade-up">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.0989993857415!2d100.41030761483006!3d13.712453490372916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e297c169fbf9f1%3A0x4002a35180e89c61!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4reC5ieC4p-C4ouC4reC4seC4meC5guC4reC4quC4liDguIjguLPguIHguLHguJQ!5e0!3m2!1sth!2sth!4v1629655500137!5m2!1sth!2sth"
                  width="100%"
                  className="responsive-iframe"
                  allowFullScreen={true}
                  loading="lazy"
                ></iframe>
              </div>
            </ColBox>
          </Row>
        </Container>
      </section>
      <section className="hd-size">
        <Container fluid>
          <Row
            className="justify-content-center"
            style={{ height: '50%', backgroundColor: color.backgroundWhite }}
          >
            <Col style={CONTENT_CENTER}>
              <div data-aos="fade-up" style={{ width: 1050, height: 480 }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.0989993857415!2d100.41030761483006!3d13.712453490372916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e297c169fbf9f1%3A0x4002a35180e89c61!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4reC5ieC4p-C4ouC4reC4seC4meC5guC4reC4quC4liDguIjguLPguIHguLHguJQ!5e0!3m2!1sth!2sth!4v1629655500137!5m2!1sth!2sth"
                  width="100%"
                  className="responsive-iframe"
                  allowFullScreen={true}
                  loading="lazy"
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* //? for screen size xs-md */}
      <section className="d-md-none d-block">
        <Container fluid style={{ paddingTop: 60, paddingBottom: 50 }}>
          <Row className="justify-content-center">
            <Col xs="10" sm="8" style={{ ...CONTENT_CENTER, maxWidth: 650 }}>
              <div data-aos="fade-up">
                <div
                  dangerouslySetInnerHTML={{ __html: t('contact') }}
                  style={{
                    ...(i18next?.language === 'th'
                      ? MOBILE_HEADER_TH
                      : MOBILE_HEADER_EN),
                    textAlign: 'center',
                  }}
                />
                <br />
                <div
                  dangerouslySetInnerHTML={{ __html: t('contact_descrition') }}
                  style={BODY_STYLE}
                />
                <br />
                <div style={{ ...BODY_2_EN, textAlign: 'center' }}>
                  FACEBOOK.COM/OUAYUN
                  <br />
                  LINE ID: @OUAYUN_OSOTH
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* //? for screen size md++ */}
      <section className="d-none d-md-block">
        <Container fluid style={CONTENT_CARD}>
          <Row className="justify-content-center">
            <Col md="7" style={{ ...CONTENT_CENTER, maxWidth: 650 }}>
              <div data-aos="fade-up">
                <div
                  dangerouslySetInnerHTML={{ __html: t('contact') }}
                  style={HEDAER_STYLE}
                />
                <br />
                <div
                  dangerouslySetInnerHTML={{ __html: t('contact_descrition') }}
                  style={BODY_STYLE}
                />
                <br />
                <div style={{ ...BODY_2_EN, textAlign: 'center' }}>
                  FACEBOOK.COM/OUAYUN
                  <br />
                  LINE ID: @OUAYUN_OSOTH
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
export default Contact;
